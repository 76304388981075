<template>
  <div class="no-data abso-center">
    <img :style="`width:${width}px`" src="@/assets/img/home/noData.png" alt="">
    <div>{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 126
    },
    text: {
      type: String,
      default: '暂无数据'
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.no-data{
  text-align:center;
      div{
        margin-top: 20px;
        .font-size(14px);
        color: @sc-grey-80;
      }
    }
</style>
