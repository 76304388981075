var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-data abso-center" }, [
    _c("img", {
      style: "width:" + _vm.width + "px",
      attrs: { src: require("@/assets/img/home/noData.png"), alt: "" },
    }),
    _c("div", [_vm._v(_vm._s(_vm.text))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }